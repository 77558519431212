.contactsContainer .contact {
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.contactsContainer .contactTitle {
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
    padding: 0;
    text-decoration: none;
    filter: drop-shadow(0 0 1rem #ffffff42);
    font-family: batmfa;
}

.contactsContainer a {
    align-self: center;
    color: grey;
    transition: all 0.2s;
    text-decoration: none;
}

.contactsContainer a .image {
    transition: filter 0.2s;
    filter: drop-shadow(0 0 0.25rem #ffffff42) brightness(0.8);
}

.contactsContainer a:hover {
    color: white;
}

.contactsContainer a:hover .image {
    filter: drop-shadow(0 0 0.25rem #ffffff8f) brightness(1);
}

.contactsContainer .image {
    height: 80px;
    align-self: center;
}
