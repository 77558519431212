.roadmapObject{
    color:"white",
}

.roadmap{
    transition: all 0.5s ease;
}

.roadmap.inactive{
    position: absolute;
    filter:opacity(0);
    pointer-events: none;
}

.roadmap.inactive.left{
    translate: -100px 0;
}

.roadmap.inactive.right{
    translate: 100px 0;
}

.roadmapButton{
    user-select: none;
    padding: 20px;
    font-size: 30px;
    font-family: nanumgothic;
    color: white;    
    translate: 0 0;
    transition: all 0.5s ease;
}
.roadmapButton.inactive{
    color: grey;
    pointer-events: none;
}  

.roadmapButton.left.active:hover{
    translate: -8px 0;
}

.roadmapButton.right.active:hover{
    translate: 8px 0;
}
