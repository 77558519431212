@media only screen and (max-width: 800px) {
    .tokenomicsContainer {
        flex-direction: column;
        flex-wrap: wrap;
    }
}

.tokenomicsContainer {
    width: 100%;
    display: flex;
    min-height: 400px;
    padding-bottom: 20px;
    gap: 20px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}