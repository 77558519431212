.partnersContainer .image {
    height: 100%;
    filter: grayscale(30%) drop-shadow(0 0 0.1rem #ffffff42) brightness(0.7);
    transition: filter 0.2s;
    align-self: center;
    color: white;
    text-decoration: none;
}

.partnersContainer .image:hover {
    filter: grayscale(0%) drop-shadow(0 0 0.25rem #ffffff8f) brightness(1);
}
