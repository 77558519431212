@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'batmfa';
    src: url('/src/static/fonts/batmfa.ttf') format('truetype');
}

@font-face {
    font-family: 'nanumgothic';
    src: url('/src/static/fonts/NanumGothic-Regular.ttf') format('truetype');
}

h1 {
    font-size:30px;
    color:white;
    font-family:"batmfa";
    text-align:center;
    margin:10px;
}